import { useCurrentProduct } from 'hooks';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { sendGAEvent, sendPaymentSuccessfulEvent } from 'utils/tracking';

import routes from 'router/routes';

import { PaymentInformationProps } from 'components/PaymentInformation';

import { SBBProductSummaryProps } from '../../components/SBBProductSummary';
import { getProductDetails } from '../../utils/getProductDetails';
import { getSwissTravelPaymentInputPageQueryParams } from '../../utils/query-params';

/**
 * Retrieve data for the Swiss Travel PaymentInputPage.
 */
export const usePaymentInputPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const swissTravelPaymentInputPageQueryParams = useMemo(() => {
    return getSwissTravelPaymentInputPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(
    swissTravelPaymentInputPageQueryParams.searchResultID,
  );
  const _currentProduct = currentProduct;
  const {
    price,
    currency,
    providerId,
    providerName,
    duration,
    startDate,
    passengersCount,
    termsUrl,
  } = getProductDetails(_currentProduct);
  const paymentFormRef = useRef<HTMLFormElement>(null);

  const isPageReady = Boolean(_currentProduct);

  // Define PaymentInputForm Props
  const paymentInformationFormProps = useMemo(() => {
    if (!_currentProduct) {
      return null;
    }

    const _props: PaymentInformationProps = {
      ref: paymentFormRef,
      amount: {
        price,
        currency,
      },
      stripeMerchantLabel: providerName || providerId,
      productTrackingId: 'transfers',
      serviceProviderId: providerId,
      bookingIntentId: swissTravelPaymentInputPageQueryParams.bookingIntentId,
      userId: swissTravelPaymentInputPageQueryParams.userId as string,
      touchPointId: swissTravelPaymentInputPageQueryParams.touchpoint as string,
      decisionId: swissTravelPaymentInputPageQueryParams.DecisionID_PPFI as string,
      shoppingCartId: swissTravelPaymentInputPageQueryParams.shoppingCartId,
      onPayment: (response) => {
        const bookingConfirmationPageQueryParams = {
          bookingId: response.id,
        };

        sendPaymentSuccessfulEvent(swissTravelPaymentInputPageQueryParams.bookingIntentId);

        navigate({
          pathname: routes.SWISS_TRAVEL_PASS_BCP,
          search: getURLSearchQuery(bookingConfirmationPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [
    _currentProduct,
    swissTravelPaymentInputPageQueryParams,
    price,
    currency,
    providerId,
    providerName,
    navigate,
  ]);

  // Define Swiss TravelProductSummary Props
  const swissTravelProductSummaryProps = useMemo(() => {
    if (!_currentProduct) {
      return null;
    }

    const _props: SBBProductSummaryProps = {
      duration,
      date: startDate,
      passengersCount,
      price,
      currency,
      termsDetails: {
        termsUrl,
        productDetails: {
          providerId,
          providerName,
        },
      },
      onClick() {
        if (paymentFormRef && paymentFormRef.current) {
          const paymentForm = paymentFormRef.current;
          paymentForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          sendGAEvent({
            event: 'pip_cta_clicked',
            category: 'airport-transfer-sbb-pip',
            label: 'airport_transfer_sbb_pip_cta_clicked',
          });
        }
      },
      isDisabled: false,
      isLoading: false,
      submitBtnLabel: t('Pay now'),
    };
    return _props;
  }, [
    _currentProduct,
    paymentFormRef,
    t,
    duration,
    startDate,
    passengersCount,
    price,
    currency,
    providerId,
    providerName,
    termsUrl,
  ]);

  useEffect(() => {
    if (!isPageReady) {
      return;
    }

    sendGAEvent({
      event: 'page_loaded',
      category: 'airport-transfer-sbb-pip',
      label: 'airport_transfer_sbb_pip_loaded',
    });
  }, [isPageReady]);

  return {
    isPageReady,
    paymentInformationFormProps,
    swissTravelProductSummaryProps,
  };
};
