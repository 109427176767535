/**
 * # Parking Product Details
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, concatenateDateTime, formatLocalDate } from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import GoogleMapReact from 'google-map-react';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getVariables } from 'utils/yiluEnv';

import { CarparkType } from 'modules/parking/utils/constants';

import './styles.scss';
import { ParkingProductDetailsProps } from './types';

const MAX_RATING = 5;
export const NUMBER_OF_COLLAPSED_SERVICES = 6;
const MAPS_ZOOM_LEVEL = 16;
const LINKOUT_MAP_ZOOM_LEVEL = 16;

const resolveParkingTypeMessage = (t: TFunction, carparkType: CarparkType | string) => {
  switch (carparkType) {
    case CarparkType.GARAGE:
      return t('parking.product.details.type.garage');
    case CarparkType.UNDERGROUND:
      return t('parking.product.details.type.underground');
    default:
      return t('parking.product.details.type.outdoor');
  }
};

const parkingServiceListItem = (service: string) => {
  return (
    <li key={service}>
      <ElementsSDK.Icon.Check className="" />
      <ElementsSDK.Typography variant="p1">{service}</ElementsSDK.Typography>
    </li>
  );
};

type PinProps = {
  lat: number;
  lng: number;
};

const Pin: FC<PinProps> = ({ lat, lng }) => (
  // @ts-ignore
  <div className="yilu-ProductDetails__Parking-Map-Pin" lat={lat} lng={lng}>
    <ElementsSDK.Icon.ParkingPin />
  </div>
);

export const ParkingProductDetails: React.FC<ParkingProductDetailsProps> = ({
  providerInfo: {
    providerId,
    logo,
    name,
    rating,
    features,
    address,
    postalCode,
    supportedLanguages,
    directions,
    extendedDescription,
    images,
    latitude,
    longitude,
    securityMeasures,
    disabledFacilities,
    arrivalProcedures,
    transfer,
  },
  optionInfo: { productDescription, carparkType },
  flightInfo,
  displayingTimezone,
}) => {
  const { t } = useTranslation();

  const productDetailsInfos: React.ReactElement[] = [];
  const bookingDetails: React.ReactElement[] = [];

  const fullAddress =
    !address.includes(postalCode) && postalCode ? `${address}, ${postalCode}` : address;

  const googleMapStyles = [
    {
      featureType: 'poi.business',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ];

  const addSeparator = () =>
    productDetailsInfos.push(
      <span
        key={`separator-${productDetailsInfos.length}`}
        className="yilu-ProductDetails__Parking__Separator"
      />,
    );

  productDetailsInfos.push(
    <div key="logo-rating" className={clsx('yilu-ProductDetails__Parking-Provider')}>
      {logo && (
        <img
          src={logo}
          alt={t('Provider logo')}
          className="yilu-ProductDetails__Parking-Provider-Logo"
        />
      )}
      <div>
        <ElementsSDK.Typography variant="h1">{name}</ElementsSDK.Typography>
        {providerId === 'PARK_AERO' && (
          <div>
            <ElementsSDK.Chip
              type="active"
              text={t('search.result.card.parking.chip')}
              className={'yilu-ProductDetails__Parking-Provider-Airport-Chip'}
            />
          </div>
        )}
        {!!rating && <ElementsSDK.Rating rating={rating} maxRating={MAX_RATING} showNumbers />}
      </div>
    </div>,
  );

  if (images?.length) {
    productDetailsInfos.push(
      <section key="image" className={'yilu-ProductDetails__Parking-Image'}>
        <img src={images[0]} alt={t('Provider Image')} loading="lazy" />
      </section>,
    );
  }

  bookingDetails.push(
    <>
      <div className="yilu-ProductDetails__icon">
        <ElementsSDK.Icon.PinOutline />
      </div>
      <dl>
        <dd>
          <address>
            <ElementsSDK.Typography variant="p1">
              <span dangerouslySetInnerHTML={{ __html: fullAddress }}></span>
            </ElementsSDK.Typography>
          </address>
        </dd>
      </dl>
    </>,
  );

  if (carparkType) {
    bookingDetails.push(
      <>
        <div className="yilu-ProductDetails__icon">
          <ElementsSDK.Icon.Parking />
        </div>
        <dl>
          <dt>
            <ElementsSDK.Typography variant="p1">
              {t('parking.product.details.type.label')}:
            </ElementsSDK.Typography>
          </dt>
          <dd>
            <ElementsSDK.Typography variant="p1">
              {resolveParkingTypeMessage(t, carparkType)}
            </ElementsSDK.Typography>
          </dd>
        </dl>
      </>,
    );
  }

  if (flightInfo) {
    bookingDetails.push(
      <>
        <div className="yilu-ProductDetails__icon">
          <ElementsSDK.Icon.Airplane />
        </div>
        <dl>
          <dt>
            <ElementsSDK.Typography variant="p1">
              {t('Flight Info')} — {t('Departure')}:
            </ElementsSDK.Typography>
          </dt>
          <dd>
            <ElementsSDK.Typography variant="p1">
              {flightInfo.airportName && (
                <>
                  {' '}
                  {flightInfo.airportName} <br />{' '}
                </>
              )}
              {formatLocalDate(
                concatenateDateTime(flightInfo.departureDate, flightInfo.departureTime),
                DateFormat.DATE_WITH_TIME,
                displayingTimezone,
              )}
            </ElementsSDK.Typography>
          </dd>
        </dl>
      </>,
    );
  }

  if (supportedLanguages.length) {
    bookingDetails.push(
      <>
        <div className="yilu-ProductDetails__icon">
          <ElementsSDK.Icon.Globe />
        </div>
        <dl>
          <dt>
            <ElementsSDK.Typography variant="p1">{t('Languages spoken')}:</ElementsSDK.Typography>
          </dt>
          <dd>
            <ElementsSDK.Typography variant="p1">
              {supportedLanguages.join(', ')}
            </ElementsSDK.Typography>
          </dd>
        </dl>
      </>,
    );
  }

  productDetailsInfos.push(
    <section key="details" className="yilu-ProductDetails__Parking__Section">
      <ElementsSDK.Typography variant="h3" className="yilu-ProductDetails__Parking__Section-Title">
        {t('Header - Details')}
      </ElementsSDK.Typography>
      <ul key="booking" className="yilu-ProductDetails__Parking__BookingDetails">
        {bookingDetails.map((component, index) => (
          <li
            key={index}
            className={clsx(
              'yilu-ProductDetails__Parking__BookingDetails--Row',
              'yilu-ProductDetails__ParkingItem',
            )}
          >
            {component}
          </li>
        ))}
      </ul>
    </section>,
  );

  if (transfer) {
    productDetailsInfos.push(
      <ElementsSDK.Typography
        key="listing-summary"
        variant="p1"
        className="yilu-ProductDetails__Parking__ProductDescription"
      >
        <strong>{t('parking.pdp.transfer.header')}</strong>
        <div>{transfer}</div>
      </ElementsSDK.Typography>,
    );
  }

  if (directions) {
    productDetailsInfos.push(
      <ElementsSDK.Typography
        key="direction-text"
        variant="p1"
        className="yilu-ProductDetails__Parking__Directions"
      >
        <strong>{t('parking.pdp.directions.header')}</strong>
        <div>{directions}</div>
      </ElementsSDK.Typography>,
    );
  }

  if (productDescription) {
    productDetailsInfos.push(
      <ElementsSDK.Accordion
        labelVariant={'p1'}
        label={{
          open: productDescription.length >= 330 ? t('See less') : undefined,
          closed: t('See more'),
        }}
        expandUp={true}
        expanded={productDescription.length < 330}
      >
        <ElementsSDK.Typography
          key="listing-summary"
          variant="p1"
          dangerouslySetInnerHTML={{ __html: productDescription }}
          className="yilu-ProductDetails__Parking__ProductDescription"
        />
      </ElementsSDK.Accordion>,
    );
  }

  const extendedDescriptionComponent = (
    <ElementsSDK.Typography
      className={'yilu-ProductDetails__Parking__ExtendedDescription'}
      variant="p1"
      dangerouslySetInnerHTML={{ __html: extendedDescription }}
    />
  );

  if (extendedDescription && extendedDescription.length >= 330) {
    productDetailsInfos.push(
      <ElementsSDK.Accordion
        key="description"
        className="yilu-ProductDetails__Parking__AccordionItem"
        label={{ open: t('See less'), closed: t('See more') }}
        expandUp
        showIcon
      >
        {extendedDescriptionComponent}
      </ElementsSDK.Accordion>,
    );
  } else if (extendedDescription && extendedDescription.length < 330) {
    productDetailsInfos.push(extendedDescriptionComponent);
  }

  addSeparator();

  if (features.length) {
    productDetailsInfos.push(
      <section
        key="facilities"
        className={clsx(
          'yilu-ProductDetails__Parking__Section',
          'yilu-ProductDetails__Parking__Facilities',
        )}
      >
        <ElementsSDK.Typography
          variant="h3"
          className={'yilu-ProductDetails__Parking__Section-Title'}
        >
          {t('Facilities')}
        </ElementsSDK.Typography>
        <ul className="yilu-ProductDetails__Parking__CheckList">
          {features
            .slice(0, NUMBER_OF_COLLAPSED_SERVICES)
            .map((service) => parkingServiceListItem(service))}
        </ul>
        {features.length > NUMBER_OF_COLLAPSED_SERVICES && (
          <ElementsSDK.Accordion
            labelVariant="p1"
            label={{ open: t('See less'), closed: t('See more') }}
            showIcon
            expandUp
          >
            <ul className="yilu-ProductDetails__Parking__CheckList">
              {features
                .slice(NUMBER_OF_COLLAPSED_SERVICES, features.length)
                .map((service) => parkingServiceListItem(service))}
            </ul>
          </ElementsSDK.Accordion>
        )}
      </section>,
    );

    addSeparator();
  }

  if (address) {
    const defaultProps = latitude &&
      longitude && {
        center: {
          lat: latitude,
          lng: longitude,
        },
        zoom: MAPS_ZOOM_LEVEL,
      };

    const yiluEnv = getVariables();

    productDetailsInfos.push(
      <section
        key="directions"
        className={clsx(
          'yilu-ProductDetails__Parking__Section',
          'yilu-ProductDetails__Parking__Directions',
        )}
      >
        <ElementsSDK.Typography
          variant="h3"
          className={'yilu-ProductDetails__Parking__Section-Title'}
        >
          {t('Directions')}
        </ElementsSDK.Typography>
        <ElementsSDK.Link
          href={`https://www.google.com/maps/dir//${latitude},${longitude}/@${latitude},${longitude},${LINKOUT_MAP_ZOOM_LEVEL}z?entry=ttu`}
          target={'_blank'}
          rel={'noopener noreferrer'}
          hasDefaultIcon
        >
          <ElementsSDK.Typography variant="p1">
            {t('parking.product.details.map.link.label')}
          </ElementsSDK.Typography>
        </ElementsSDK.Link>

        {defaultProps && (
          <div className="yilu-ProductDetails__Parking-Map">
            <GoogleMapReact
              bootstrapURLKeys={{ key: yiluEnv.GOOGLE_MAPS_KEY! }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              options={{
                styles: googleMapStyles,
              }}
            >
              <Pin lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
            </GoogleMapReact>
          </div>
        )}
      </section>,
    );
  }

  return <div className="yilu-ProductDetails__Parking">{productDetailsInfos}</div>;
};
