/**
 * # Lounges Product Details
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { dateToWeekIndex, useIsMobile } from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';

import { ImageSlider } from 'components/ImageSlider';

import {
  LoungeAdditionalInformation,
  LoungeDirections,
  LoungeImportantInformation,
} from 'modules/lounges/components/';
import {
  AmenityWithIcon,
  LoungeAmenity,
  matchAmenitiesWithIcons,
} from 'modules/lounges/components/LoungeAmenity';
import LoungeFaq from 'modules/lounges/components/LoungeFaq';
import { LoungeMetaList } from 'modules/lounges/pages/ProductDetailsPage/LoungeProductDetails/LoungeMetaList';
import type { Amenities } from 'modules/lounges/types';
import { FRANKFURT_LOUNGE_ID, LOUNGES_WITH_WARNING } from 'modules/lounges/utils/constants';
import { LoungeServiceProvider, isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';

import './styles.scss';
import { LoungeProductDetailsProps } from './types';

const MAX_AMENITIES_MOBILE = 2;
const MAX_AMENITIES_DESKTOP = 4;

export const LoungeProductDetails: React.FC<LoungeProductDetailsProps> = ({
  name,
  imageUrl,
  imageUrls,
  location,
  amenities,
  openingHours,
  marketingText,
  date,
  importantInformation,
  additionalInformation,
  iata,
  loungeOptionId,
  openingTimesMessage,
  needToKnow,
  checkInTime,
  freeCancellations,
  serviceProviderType,
  faqLink,
}) => {
  const { t } = useTranslation();
  const yiluEnvVariables = yiluEnv.getVariables();
  const partnerId = yiluEnvVariables.YILU_ENTERPRISE_PARTNER
    ? yiluEnvVariables.YILU_ENTERPRISE_PARTNER.toUpperCase()
    : 'LH';

  const searchedDateOfWeek = dateToWeekIndex(date);
  const showAlert = LOUNGES_WITH_WARNING.includes(iata && iata.toUpperCase());
  const showFrankfurtLoungeWarning = loungeOptionId === FRANKFURT_LOUNGE_ID;

  const { isMobile } = useIsMobile();

  const MAX_AMENITIES_LENGTH = isMobile ? MAX_AMENITIES_MOBILE : MAX_AMENITIES_DESKTOP;

  const isAmenitiesCollapsable = amenities.length > MAX_AMENITIES_LENGTH;
  const productDetailsInfos: React.ReactElement[] = [];

  const addSeparator = () =>
    productDetailsInfos.push(
      <span key={productDetailsInfos.length} className="yilu-ProductDetails__Lounges__Separator" />,
    );

  const imageUrlsArray = imageUrls && imageUrls.length > 0 ? imageUrls : [imageUrl];
  productDetailsInfos.push(
    <div key="logo">
      <ImageSlider
        images={imageUrlsArray.map((imageUrl: string, index: number) => ({
          imageUrl,
          alt: t(`Lounge Image`, { index: index + 1 }),
        }))}
      />
    </div>,
  );

  const isHolidayExtras = serviceProviderType && isSpHolidayExtras(serviceProviderType);

  const AmenityListItem = (amenity: AmenityWithIcon) => {
    return (
      <div key={amenity.name} className="yilu-ProductDetails__Lounges__Amenity">
        <LoungeAmenity className="yilu-ProductDetails__Lounge__LoungeIcon" icon={amenity.icon} />
        <ElementsSDK.Typography>
          {amenity.name ? t(amenity.name) : amenity.name}
        </ElementsSDK.Typography>
      </div>
    );
  };

  productDetailsInfos.push(
    <React.Fragment key="header">
      <ElementsSDK.Typography variant="h2" className="yilu-ProductDetails__Lounges_Header">
        {name}
      </ElementsSDK.Typography>
      {showAlert && (
        <ElementsSDK.Message
          className="yilu-ProductDetails__Lounges_Warning"
          type={ElementsSDK.MessageType.WARNING}
          content={t('lounge.pdp.description.alert')}
        />
      )}
      {showFrankfurtLoungeWarning && (
        <ElementsSDK.Message
          className="yilu-ProductDetails__Lounges_Warning"
          type={ElementsSDK.MessageType.WARNING}
          content={t('lounge.pdp.frankfurt.alert')}
        />
      )}
    </React.Fragment>,
  );

  productDetailsInfos.push(
    <LoungeMetaList
      isHolidayExtras={isHolidayExtras}
      location={location}
      openingHours={openingHours}
      searchedDateOfWeek={searchedDateOfWeek}
    />,
  );
  addSeparator();

  if (marketingText) {
    productDetailsInfos.push(
      <ElementsSDK.Typography
        variant="p1"
        dangerouslySetInnerHTML={{
          __html: marketingText,
        }}
        className="yilu-ProductDetails__Lounges_Info"
      />,
    );
    addSeparator();
  }

  const allAmenities: Amenities[] = amenities;

  if (allAmenities.length > 0) {
    const matchedAmenities = matchAmenitiesWithIcons(allAmenities);

    productDetailsInfos.push(
      <section key="amenities">
        <ElementsSDK.Typography variant="h2">{t('Complimentary Amenities')}</ElementsSDK.Typography>
        <div
          className={clsx(
            'yilu-ProductDetails__Lounges__AmenitiesList',
            'yilu-ProductDetails__Lounges_Info',
          )}
        >
          {matchedAmenities
            .slice(0, MAX_AMENITIES_LENGTH)
            .map((amenity) => AmenityListItem(amenity))}
        </div>
        {isAmenitiesCollapsable && (
          <ElementsSDK.Accordion
            labelVariant={'p1'}
            label={{ open: t('See less'), closed: t('See more') }}
            expandUp={true}
          >
            <div
              className={clsx(
                'yilu-ProductDetails__Lounges__AmenitiesList',
                'yilu-ProductDetails__Lounges_Info',
              )}
            >
              {matchedAmenities
                .slice(MAX_AMENITIES_LENGTH, matchedAmenities.length)
                .map((amenity) => AmenityListItem(amenity))}
            </div>
          </ElementsSDK.Accordion>
        )}
      </section>,
    );
    addSeparator();
  }

  if (importantInformation !== '') {
    productDetailsInfos.push(
      <LoungeImportantInformation key="imp-info" content={importantInformation} />,
    );
    addSeparator();
  }

  if (additionalInformation?.length || needToKnow?.length) {
    productDetailsInfos.push(
      <LoungeAdditionalInformation
        key="add-info"
        additionalInfo={additionalInformation}
        needToKnow={needToKnow}
      />,
    );
    addSeparator();
  }

  productDetailsInfos.push(<LoungeDirections key="directions" directions={location} />);
  addSeparator();

  if (freeCancellations) {
    productDetailsInfos.push(
      <div key={'free-cancellations'}>
        <ElementsSDK.Typography variant="h2">
          {t('Lounges.freeCancellations.title')}
        </ElementsSDK.Typography>
        <div>
          <ElementsSDK.Typography variant="p1">
            {t('Lounges.freeCancellations.text')}
          </ElementsSDK.Typography>
        </div>
      </div>,
    );
    addSeparator();
  }

  if (openingTimesMessage || checkInTime) {
    productDetailsInfos.push(
      <div key={'opening-times'}>
        <ElementsSDK.Typography variant="h2">{t('Opening Hours')}</ElementsSDK.Typography>
        {openingTimesMessage && (
          <>
            <ElementsSDK.Typography variant="h4">{openingTimesMessage}</ElementsSDK.Typography>
            <br />
          </>
        )}
        {checkInTime && <ElementsSDK.Typography variant="p1">{checkInTime}</ElementsSDK.Typography>}
      </div>,
    );
    addSeparator();
  }

  if (
    (partnerId === 'LH' || partnerId === 'ST') &&
    serviceProviderType !== LoungeServiceProvider.HOLIDAY_EXTRAS
  ) {
    const faqs = Array.from({ length: 5 }, (_, i) => ({
      question: t(`pdp.loungeFaq.question${i + 1}`),
      answer: t(`pdp.loungeFaq.answer${i + 1}`),
    }));

    productDetailsInfos.push(<LoungeFaq faqs={faqs} contactLink={faqLink} />);
  }

  return <div className="yilu-ProductDetails__Lounges">{productDetailsInfos}</div>;
};
