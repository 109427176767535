/**
 * # Search Result Card - Ride
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { formatCurrency } from '@yiluhub/ui-utilities';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { formatUtils } from 'utils';

import { SearchResultCard } from 'components';

import { useRidesData } from 'modules/rides/utils/useRidesData';

import './styles.scss';

export type RidesSearchResultCardProps = {
  data: SearchItem;
  onClick?(data: SearchItem): unknown;
  cardRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>> | undefined;
};

export const RidesSearchResultCard: React.FC<RidesSearchResultCardProps> = ({
  data,
  onClick,
  cardRef,
}) => {
  const { t } = useTranslation();
  const {
    currency,
    price,
    carrierName,
    providerClassification,
    imageUrl,
    maxSeats,
    carrierLogoUrl,
    driversWaitingMinutesIncluded,
    luggage,
    model,
    arrivalDateTime,
    departureDateTime,
  } = useRidesData(data);

  const onClickCb = useCallback(() => {
    onClick && onClick(data);
  }, [onClick, data]);

  return (
    <button onClick={onClickCb}>
      <SearchResultCard className="yilu-SearchResultItem__ride" ref={cardRef}>
        <div className={clsx('ride-info', 'section-gap', 'ride-info-main-container')}>
          <img src={imageUrl} alt={t('Ride class picture')} className="ride-class-image" />
          <div className="ride-info-container">
            <ElementsSDK.Typography size="medium" aria-label={t('Ride Name')}>
              {model}
            </ElementsSDK.Typography>
            <div className="ride-features">
              <img
                src={carrierLogoUrl}
                alt={t('Carrier class picture')}
                className="carrier-class-image"
              />
              <ElementsSDK.Typography
                size="small"
                className="carrier-class-name"
                aria-label={t('Carrier name')}
              >
                {carrierName}
              </ElementsSDK.Typography>
            </div>
            {!!arrivalDateTime && !!departureDateTime && (
              <div className="ride-features">
                <ElementsSDK.Icon.Taxi className="icon-taxi" />
                <ElementsSDK.Typography
                  size="small"
                  className="carrier-class-name"
                  aria-label={t('Carrier time')}
                >
                  {formatUtils.formatDuration(t, departureDateTime, arrivalDateTime)}
                </ElementsSDK.Typography>
              </div>
            )}
          </div>
        </div>

        <div className={clsx('ride-info', 'section-gap', 'ride-info-features')}>
          <ElementsSDK.Typography variant="h3" aria-label={t('Ride class')}>
            {providerClassification}
          </ElementsSDK.Typography>
          <div className="ride-features">
            <ElementsSDK.Typography
              size="small"
              className="ride-features-seats"
              aria-label={t('Seats count')}
            >
              <ElementsSDK.Icon.Seat className="yilu-SearchResultItem__Ride__FeatureIcon" />
              {t('seats', { passengers: maxSeats })}
            </ElementsSDK.Typography>
          </div>

          <div className="ride-features">
            <ElementsSDK.Typography
              size="small"
              className="ride-features-seats"
              aria-label={t('Bags count')}
            >
              <ElementsSDK.Icon.CustomLuggage className="yilu-SearchResultItem__Ride__FeatureIcon" />
              {t('bags', { bags: luggage })}
            </ElementsSDK.Typography>
          </div>

          <div className="ride-features">
            <ElementsSDK.Typography
              size="small"
              className="ride-features-seats"
              aria-label={t('Waiting time')}
            >
              <ElementsSDK.Icon.Time className="yilu-SearchResultItem__Ride__FeatureIcon" />
              {t('waitingTime', { time: driversWaitingMinutesIncluded })}
            </ElementsSDK.Typography>
          </div>

          <div className="ride-features">
            <ElementsSDK.Typography
              size="small"
              className="ride-features-seats"
              aria-label={t('rides.cancellation_policy.text')}
            >
              <ElementsSDK.Icon.InfoOutlined className="yilu-SearchResultItem__Ride__FeatureIcon" />
              {t('rides.cancellation_policy.text')}
            </ElementsSDK.Typography>
          </div>
        </div>

        <div className={clsx('ride-info', 'section-gap', 'call-to-action--section', 'cta-btn')}>
          <div>
            <ElementsSDK.Typography
              size="medium"
              variant="h3"
              aria-label={t('Ride class')}
              bold
              inline
            >
              {t('Total')}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography
              size="medium"
              variant="p4"
              aria-label={t('Ride class')}
              inline
              className="vat-included-text"
            >
              {t('VAT included')}
            </ElementsSDK.Typography>
          </div>
          <ElementsSDK.Typography
            size="h3"
            variant="h3"
            aria-label={t('Ride class')}
            bold={true}
            translate="no"
          >
            {formatCurrency(price, currency)}
          </ElementsSDK.Typography>
          <div className={clsx('call-to-action--desktop')}>
            <ElementsSDK.Typography
              size="h4"
              className="select-ride-btn"
              aria-label={t('Bags count')}
            >
              <button className="call-to-action">
                {t('Select')}
                <ElementsSDK.Icon.ArrowRightChevron className="call-to-action-right-chevron" />
              </button>
            </ElementsSDK.Typography>
          </div>
        </div>
        <div className={clsx('ride-info', 'section-gap', 'call-to-action--mobile', 'cta-btn')}>
          <ElementsSDK.Typography
            size="h4"
            className="select-ride-btn--mobile"
            aria-label={t('Bags count')}
          >
            <button className="call-to-action--mobile-btn">
              {t('Select')}
              <ElementsSDK.Icon.ArrowRightChevron className="call-to-action-right-chevron--mobile" />
            </button>
          </ElementsSDK.Typography>
        </div>
      </SearchResultCard>
    </button>
  );
};
