import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import {
  DateFormat,
  concatenateDateTime,
  formatLocalDate,
  getYiluConfigLocale,
  handleValidateDateTimeInputError,
  isDateTimeInputValid,
} from '@yiluhub/ui-utilities';
import { GetAirportConnectedStationsByStoreIdResponse } from '@yiluhub/yilu-amp-types';
import { TransferDirection } from 'applicationConstants';
import axios from 'axios';
import dayjs from 'dayjs';
import querystring from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { yiluEnv } from 'utils';
import { setParamsToBeRemoved } from 'utils/session';

import { TransferType } from '../../../utils/constants';
import { AirportTransferSearchFormProps, AirportTransferSearchOutputParams } from '../types';

export type UseAirportTransferSearchFormParams = Pick<
  AirportTransferSearchFormProps,
  'onSubmit' | 'searchInput' | 'onError' | 'googleMapsAPIKey'
>;

const DEFAULT_INITIAL_DIRECTION = TransferDirection.FROM_AIRPORT;
const DEFAULT_INITIAL_TRANSFER_TYPE = TransferType.DEPARTURE;
const DEFAULT_INITIAL_TIME_OFFSET_VALUE = 3;
const DEFAULT_INITIAL_TIME_OFFSET_UNIT = 'hour';
const DEFAULT_INITIAL_TRAVELLERS_COUNT = 1;

export function useAirportTransferSearchForm({
  googleMapsAPIKey,
  searchInput,
  onError,
  onSubmit,
}: UseAirportTransferSearchFormParams) {
  const { airportDetails = null } = searchInput || {};
  const locale = getYiluConfigLocale();
  const location = useLocation();

  // Google Location Input
  const locationDetails = ElementsSDK.useAddressDetails(
    googleMapsAPIKey,
    searchInput && searchInput.location,
    onError,
  );
  const [locationAddress, setLocationAddress] = useState(locationDetails);

  // Airport Dropdown
  const [selectedAirportDetails, setSelectedAirportDetails] = useState(airportDetails);
  const [connectedStationsData, setConnectedStationsData] = useState<any>([]);
  const [selectedAirportName, setSelectedAirportName] = useState<string | null>(
    airportDetails && airportDetails.localizations![locale].name,
  );
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const iataCode = queryParams?.airportDetails?.iataCode; // for easier reference

  const fetchConnectedStationsForAirport = useCallback(async (iataCode: string) => {
    const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID } = yiluEnv.getVariables();

    const { data } = await axios.get<GetAirportConnectedStationsByStoreIdResponse>(
      `${YILU_AMP_BACKEND_URL}/store/v1/stores/${YILU_AMP_STORE_ID}/locations/${iataCode}/stations`,
    );

    const connectedStationForAirport = data?.map(({ city, stations }) => {
      return {
        city,
        stations: stations
          .map((station) => {
            station.location = {
              ...station.location,
              longitude: station.location.lng,
              latitude: station.location.lat,
            } as any;
            return {
              address: station.address,
              id: station.id,
              name: station.name,
              provider: station.provider,
              stationType: station.stationType,
              location: station.location,
              city: station.city,
            };
          })
          .filter((station) => !!station.address),
      };
    });
    return connectedStationForAirport;
  }, []);
  const fetchConnectedStations = useCallback(async () => {
    if (iataCode) {
      const connectedStationForAirport = await fetchConnectedStationsForAirport(iataCode);
      const cleanedConnectedStationsForAirport = connectedStationForAirport?.map((s) => {
        const stations = [...s.stations];
        const filteredStations = stations.filter((station) => station?.address?.length > 0);
        s.stations = filteredStations;
        return s;
      });
      setConnectedStationsData(cleanedConnectedStationsForAirport);
    }
  }, [fetchConnectedStationsForAirport, iataCode]);

  useEffect(() => {
    fetchConnectedStations();
  }, []);

  const handleOnAirportSelect = useCallback(
    async (airportDetails) => {
      setLocationAddress(null);
      setConnectedStationsData([]);
      setSelectedAirportDetails(airportDetails);
      setSelectedAirportName(airportDetails.localizations[locale].name);
      const connectedStationForAirport = await fetchConnectedStationsForAirport(
        airportDetails.iataCode,
      );
      setConnectedStationsData(connectedStationForAirport);
      setParamsToBeRemoved(['carrier']);
    },
    [fetchConnectedStationsForAirport, locale],
  );

  // Travellers Count
  const [travellersCount, setTravellersCount] = useState(() => {
    return (searchInput && searchInput.travellersCount) || DEFAULT_INITIAL_TRAVELLERS_COUNT;
  });

  // Transfer Type
  const [transferType, setTransferType] = useState<TransferType>(DEFAULT_INITIAL_TRANSFER_TYPE);

  const transferTypeAutoSearchRef = useRef<TransferType | undefined>(undefined);

  const handleTransferTypeChange = useCallback(
    (type) => {
      setTransferType(type);
      transferTypeAutoSearchRef.current = type;
    },
    [transferType],
  );
  // Transfer Direction
  const [transferDirection, setTransferDirection] =
    useState<TransferDirection>(DEFAULT_INITIAL_DIRECTION);
  const handleTransferDirectionChange = useCallback(
    (isSRP) => {
      setTransferDirection(() => {
        const newDirection =
          transferDirection === TransferDirection.FROM_AIRPORT
            ? TransferDirection.TO_AIRPORT
            : TransferDirection.FROM_AIRPORT;
        if (isSRP) handleFormSubmit({ transferDirectionAutoSearch: newDirection });

        return newDirection;
      });
    },
    [transferDirection, selectedAirportDetails, locationDetails],
  );

  // Transfer Datetime
  const [transferDateTime, setTransferDateTime] = useState(() => {
    const initialDate =
      (searchInput && searchInput.transferDate) || dayjs().format(DateFormat.SHORT_DATE);

    const initialTime =
      (searchInput && searchInput.transferTime) ||
      dayjs()
        .add(DEFAULT_INITIAL_TIME_OFFSET_VALUE, DEFAULT_INITIAL_TIME_OFFSET_UNIT)
        .format(DateFormat.TIME);
    return concatenateDateTime(initialDate, initialTime);
  });

  const handleTransferDateTimeChange = useCallback(
    (dateTime: string) => {
      if (!isDateTimeInputValid(dateTime)) {
        handleValidateDateTimeInputError(onError);
      } else {
        setTransferDateTime(dateTime);
      }
    },
    [onError],
  );

  // Validation
  const [isSearchInputValid, setIsSearchInputValid] = useState(() => {
    return Boolean(locationAddress) && Boolean(selectedAirportDetails);
  });

  // Validation
  const handleFormInputValidate = useCallback((setValue) => {
    return (e: React.FocusEvent) => {
      const target = e.target as HTMLInputElement;
      if (!target.value) {
        setValue(null);
      }
    };
  }, []);

  // Submit
  const handleFormSubmit = useCallback(
    ({
      travellersCounterAutoSearch,
      transferDateTimeAutoSearch,
      locationAddressAutoSearch,
      transferDirectionAutoSearch,
    }: {
      travellersCounterAutoSearch?: number;
      transferDateTimeAutoSearch?: string | null;
      locationAddressAutoSearch?: ElementsSDK.GoogleSearchAddress;
      transferDirectionAutoSearch?: TransferDirection;
    } = {}) => {
      if (!selectedAirportDetails || (!locationAddress && !locationAddressAutoSearch)) {
        return;
      }

      const searchOutputParams: AirportTransferSearchOutputParams = {
        locationDetails:
          locationAddressAutoSearch || (locationAddress as ElementsSDK.GoogleSearchAddress),
        airportDetails: selectedAirportDetails,
        direction: transferDirectionAutoSearch ? transferDirectionAutoSearch : transferDirection,
        transferType: transferTypeAutoSearchRef.current || transferType,
        transferDate: transferDateTimeAutoSearch
          ? formatLocalDate(transferDateTimeAutoSearch, DateFormat.SHORT_DATE)
          : formatLocalDate(transferDateTime, DateFormat.SHORT_DATE),
        transferTime: transferDateTimeAutoSearch
          ? formatLocalDate(transferDateTimeAutoSearch, DateFormat.TIME)
          : formatLocalDate(transferDateTime, DateFormat.TIME),
        travellersCount: travellersCounterAutoSearch || travellersCount,
        stationId: locationAddressAutoSearch
          ? (locationAddressAutoSearch as any).id
          : (locationAddress as any).id,
      };
      transferTypeAutoSearchRef.current = undefined;
      onSubmit(searchOutputParams);
    },
    [
      isSearchInputValid,
      locationAddress,
      selectedAirportDetails,
      transferType,
      transferDirection,
      transferDateTime,
      travellersCount,
      onSubmit,
    ],
  );

  const onChangeLocationAddress = useCallback(
    async (address, isSRP) => {
      setLocationAddress(address);
      setParamsToBeRemoved(['carrier']);
      if (isSRP) handleFormSubmit({ locationAddressAutoSearch: address });
    },
    [handleFormSubmit, isSearchInputValid],
  );

  // Update if data are retrieved
  useEffect(() => {
    if (!locationDetails || locationAddress?.location !== '') {
      return;
    }
    setLocationAddress(locationDetails);
  }, [locationDetails, locationAddress]);

  useEffect(() => {
    // in case airportDetails come from the parent component as in deeplinking
    if (searchInput && searchInput.airportDetails) {
      setSelectedAirportDetails(searchInput.airportDetails);
      setSelectedAirportName(searchInput.airportDetails.localizations![locale].name);
    }
  }, [searchInput, locale]);

  useEffect(() => {
    if (!locationAddress || !selectedAirportDetails) {
      setIsSearchInputValid(false);
      return;
    }
    setIsSearchInputValid(true);
  }, [locationAddress, selectedAirportDetails]);

  // Update search input parts
  useEffect(() => {
    if (!searchInput) {
      return;
    }

    if (!transferType && searchInput.transferType) {
      setTransferType(searchInput.transferType);
    }

    if (searchInput.travellersCount) {
      setTravellersCount(searchInput.travellersCount);
    }

    if (searchInput.direction) {
      setTransferDirection(searchInput.direction);
    }
  }, [searchInput]);

  const handleOnCalendarClose = useCallback(
    (val, isSRP) => {
      if (isSRP && (transferTypeAutoSearchRef.current || val !== transferDateTime)) {
        handleFormSubmit({ transferDateTimeAutoSearch: val });
      }
    },
    [locationAddress, transferDateTime, transferType],
  );

  return {
    locationAddress,
    transferType,
    transferDirection,
    transferDateTime,
    travellersCount,
    isSearchInputValid,
    selectedAirportName,
    setLocationAddress,
    handleOnAirportSelect,
    onChangeLocationAddress,
    setTravellersCount,
    setTransferType,
    handleTransferDateTimeChange,
    handleTransferDirectionChange,
    handleFormInputValidate,
    handleFormSubmit,
    handleTransferTypeChange,
    handleOnCalendarClose,
    connectedStationsData,
  };
}
