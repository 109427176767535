import { SearchItem } from '@yiluhub/yilu-amp-types';

import { extractAmpProductAttribute } from 'utils/ampUtils';
import { breakCommaReplaceHtml, stripHtml } from 'utils/htmlUtils';

import { CarparkType, TravellingIcon } from './constants';

export type ProductDetails = {
  //meta
  providerId: ParkingProviderId | string;
  searchResultId: string;
  catalogId: string;
  productId?: number;
  optionId: string;
  airportId?: number;
  productTypeCode?: string;
  carParkType?: CarparkType;
  supportedLanguages?: string[];
  marketId?: string;
  terminalId?: number;
  areaId?: number;
  locationId?: string;
  listingId?: string;

  //dates
  arrival: string;
  departure: string;

  //price
  price: number;
  currency: string;
  selectedPriceId: string;

  //imagery
  logo: string;
  images: string[];
  travellingIcon?: TravellingIcon;

  //location
  address: string;
  postalCode?: string;
  transferFrequencyByShuttle: string | null;
  transferTimeByShuttle: string | null;
  transfer?: string;
  walkingDistance?: number;
  walkingDistanceTime?: number;
  latitude?: number;
  longitude?: number;
  iata?: string;
  airportName?: string;

  //details
  name: string;
  directions: string;
  productDescription: string;
  extendedDescription: string;
  rating: number | null;
  features: string[];
  policy: {
    termsUrl: string;
    termsLabel?: string;
  };
  securityMeasures?: string;
  arrivalProcedures?: string;
  disabledFacilities?: string;
  meetAndGreet?: boolean;
  onAirport?: boolean;
};

const ERROR_PRICE = 999999;

const formatParkingAddress = (address: string) => {
  // remove html tags and replace <br /> with comma
  let formattedAddress = stripHtml(breakCommaReplaceHtml(address));

  // remove space with a comma
  formattedAddress = formattedAddress.replace(/ ,/g, ',');

  // remove double commas
  formattedAddress = formattedAddress.replace(/,,/g, ',');

  return formattedAddress;
};

//FIXME: this is a temporary fix for the image urls with spaces
const formatParkingImage = (imageUrl: string) => {
  //remove spaces
  return imageUrl.replace(/\s/g, '');
};

function getAmpParkingDetails(parkingData: SearchItem) {
  // cleaner extractor for AMP attributes
  const extractAmpAttr = (key: string) => {
    try {
      return extractAmpProductAttribute(parkingData, key);
    } catch (error) {
      console.error(`Extracting AMP parking ${key}`, error);
    }
  };

  const productDescription = parkingData.item.productDetails[0]?.productDescription;
  const productId = extractAmpAttr('spProductId') || undefined;
  const airportId = extractAmpAttr('airportId') || undefined;
  const marketId = extractAmpAttr('marketId') || undefined;
  const terminalId = extractAmpAttr('terminalId') || undefined;
  const areaId = extractAmpAttr('areaId') || undefined;
  const locationId = extractAmpAttr('locationId') || undefined;
  const listingId = extractAmpAttr('listingId') || undefined;

  const walkingDistanceTime = extractAmpAttr('walkingDistanceTime');
  const walkingDistance = extractAmpAttr('walkingDistance');
  const rating = extractAmpAttr('rating');

  const securityMeasures = extractAmpAttr('securityMeasures');
  const arrivalProcedures = extractAmpAttr('arrivalProcedures');
  const disabledFacilities = extractAmpAttr('disabledFacilities');

  let features: string[] = [];
  let supportedLanguages: string[] = [];
  let images: string[] = [];

  //may throw error if the JSON is invalid
  try {
    features = JSON.parse(extractAmpAttr('features') || '[]') as string[];
    supportedLanguages = JSON.parse(extractAmpAttr('supportedLanguages') || '[]') as string[];
    images =
      productDescription.imageUrls?.map((imageUrl) => formatParkingImage(imageUrl) || '') || [];
  } catch (error) {
    console.error('Parsing AMP parking features', error);
  }

  const latitude = extractAmpAttr('parkingLocationLatitude') || undefined;
  const longitude = extractAmpAttr('parkingLocationLongitude') || undefined;
  const address = extractAmpAttr('parkingLocationAddress') || '';
  const logo = extractAmpAttr('logo') || '';

  let transferTimeByShuttle = extractAmpAttr('transferTimeByShuttle');
  if (transferTimeByShuttle?.includes('O minutes')) {
    transferTimeByShuttle = undefined;
  }

  // correct incorrect spelling
  const normalizedTransferTimeByShuttle = transferTimeByShuttle?.replace(' min', ' Min');

  return {
    searchResultId: parkingData.id,
    optionId: parkingData.id,

    catalogId: parkingData.item.catalogId,
    providerId: extractAmpAttr('serviceProvider') || '',
    productId: (productId && parseInt(productId)) || undefined,
    airportId: (airportId && parseInt(airportId)) || undefined,
    terminalId: (terminalId && parseInt(terminalId)) || undefined,
    areaId: (areaId && parseInt(areaId)) || undefined,
    marketId,
    locationId,
    listingId,

    price: parkingData.item.defaultPrice.price.amount
      ? parkingData.item.defaultPrice.price.amount / 100
      : ERROR_PRICE,
    currency: parkingData.item.defaultPrice.price.currency,
    selectedPriceId: parkingData.item.defaultPrice.id,

    name: productDescription?.name || '',

    //FIXME: logo sometimes is apart of images
    logo: (logo && formatParkingImage(logo)) || images?.[0] || '',
    productDescription: productDescription?.description || '',
    extendedDescription: extractAmpAttr('extendedDescription') || '',
    policy: {
      termsUrl: extractAmpAttr('termsUrl') || '',
      termsLabel: extractAmpAttr('cancellationPolicy') || undefined,
    },
    arrival: extractAmpAttr('startAt') || '',
    departure: extractAmpAttr('endAt') || '',

    walkingDistance: (walkingDistance && parseInt(walkingDistance)) || undefined,
    walkingDistanceTime: (walkingDistanceTime && parseInt(walkingDistanceTime)) || undefined,
    productTypeCode: extractAmpAttr('productTypeCode') || '',
    carParkType: (extractAmpAttr('carParkType') as CarparkType) || '',
    travellingIcon: (extractAmpAttr('travellingIcon') as TravellingIcon) || undefined,
    transferTimeByShuttle: normalizedTransferTimeByShuttle || null,
    transferFrequencyByShuttle: extractAmpAttr('transferFrequencyByShuttle') || null,
    transfer: extractAmpAttr('transfer') || undefined,
    rating: (rating && parseInt(rating)) || null,

    address: (address && formatParkingAddress(address)) || '',
    postalCode: extractAmpAttr('parkingLocationPostalCode') || '',
    airportName: extractAmpAttr('airportName') || '',

    latitude: (latitude && parseFloat(latitude)) || undefined,
    longitude: (longitude && parseFloat(longitude)) || undefined,

    directions: extractAmpAttr('directions') || '',
    features,
    supportedLanguages,
    images,
    securityMeasures,
    arrivalProcedures,
    disabledFacilities,
    meetAndGreet: extractAmpAttr('meetAndGreet') === 'true' || false,
    onAirport: extractAmpAttr('onAirport') === 'true' || false,
  };
}

export const getParkingDetails = (data: SearchItem | undefined | null): ProductDetails => {
  if (!data) {
    return getDefaultProductDetails();
  }

  return getAmpParkingDetails(data);
};

const getDefaultProductDetails = (): ProductDetails => {
  return {
    catalogId: '',
    arrival: '',
    departure: '',
    providerId: '',
    searchResultId: '',
    optionId: '',
    price: ERROR_PRICE,
    currency: '',
    name: '',
    address: '',
    transferFrequencyByShuttle: null,
    transferTimeByShuttle: null,
    transfer: undefined,
    rating: null,
    features: [],
    logo: '',
    policy: {
      termsUrl: '',
      termsLabel: '',
    },
    directions: '',
    productDescription: '',
    extendedDescription: '',
    images: [],
    selectedPriceId: '',
    securityMeasures: undefined,
    disabledFacilities: undefined,
    arrivalProcedures: undefined,
  };
};

export enum ParkingProviderId {
  PARK_VIA = 'PARK_VIA',
  PARK_AERO = 'PARK_AERO',
  HOLIDAY_EXTRAS = 'HolidayExtras Parking',
}
