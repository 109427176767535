/**
 * # Search Result Card - Lounge
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchResultCard } from 'components';

import { AmenityWithIcon, LoungeAmenity } from 'modules/lounges/components/LoungeAmenity';
import { getProductDetails, isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';

import { useLoungeSearchCardProps } from './hooks/useLoungeSearchCardProps';
import './styles.scss';

export type LoungeSearchResultCardProps = {
  data: SearchItem;
  searchedTravellersCount: number;
  searchedDateOfWeek: number;
  onClick?(data: SearchItem): unknown;
  cardRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>> | undefined;
};

export const LoungeSearchResultCard: React.FC<LoungeSearchResultCardProps> = ({
  data,
  searchedTravellersCount,
  searchedDateOfWeek,
  onClick,
  cardRef,
}) => {
  const { t } = useTranslation();

  const {
    name,
    currency,
    price,
    openingHours,
    amenities,
    thumbnailUrl,
    imageUrls,
    terminal,
    airside,
    providerId,
    date,
    providerName,
  } = getProductDetails(data);

  const isHolidayExtras = isSpHolidayExtras(providerId);

  const { isAvailable, onClickCb, openingHoursText, availableLoungeOptions, dayName, imageUrl } =
    useLoungeSearchCardProps({
      data,
      date,
      onClick,
      openingHours,
      searchedDateOfWeek,
      amenities,
      thumbnailUrl,
      imageUrls,
      isHolidayExtras,
    });

  let securityText = '';

  if (airside === true) {
    securityText = t('(After Security)');
  } else if (airside === false) {
    securityText = t('(Before Security)');
  }

  return (
    <SearchResultCard onClick={onClickCb} className="yilu-SearchResultItem__lounge" ref={cardRef}>
      <div className="yilu-Lounge">
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={t('Lounge Image')}
            className={clsx('yilu-Lounge__Image', {
              'yilu-Lounge__Image--rightAlign': isHolidayExtras,
            })}
          />
        ) : (
          <div className="yilu-Lounge__Image" />
        )}
        <div className="yilu-Lounge__Details">
          <div className="yilu-Lounge__ProviderDetails">
            <ElementsSDK.Typography variant="h4" className="yilu-Lounge__Header">
              {name}
            </ElementsSDK.Typography>
            {!isHolidayExtras && (
              <ElementsSDK.Chip
                className="yilu-Lounge__Chip"
                type="active"
                text={t('search.result.card.lounge.chip', { spName: providerName })}
              />
            )}
            <div className="yilu-Lounge__Info">
              <div className="yilu-Lounge__Terminal">
                <ElementsSDK.Typography size="medium">
                  {t('Terminal {{terminal}}', {
                    terminal,
                  })}{' '}
                  {securityText}
                </ElementsSDK.Typography>
              </div>
              {dayName && openingHoursText && (
                <div className="yilu-Lounge__Terminal">
                  <ElementsSDK.Typography size="medium">
                    {`${dayName} • ${openingHoursText}`}
                  </ElementsSDK.Typography>
                </div>
              )}
              <div className="yilu-Lounge__LoungeOptions">
                {availableLoungeOptions.map((amenity: AmenityWithIcon, index: number) => (
                  <React.Fragment key={index}>
                    {/* Three dots icon name is undefined to disable hover effect */}
                    <ElementsSDK.Tooltip
                      tooltipText={amenity.name ? t(amenity.name) : amenity.name}
                    >
                      <LoungeAmenity
                        className="yilu-Lounge__LoungeOptionIcon"
                        icon={amenity.icon}
                      />
                    </ElementsSDK.Tooltip>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <section className="yilu-Lounge__SearchResultItem__Footer">
            <div className="yilu-Lounge__SearchResultItem__ProviderInfo">
              <div className="yilu-Lounge__SearchResultItem__AdditionalInfo">
                <span className="yilu-Lounge__SearchResultItem__TotalPersons">
                  <ElementsSDK.Icon.PersonOutline />
                  <ElementsSDK.Typography
                    font="bold"
                    size="extraSmall"
                    className="yilu-Lounge__SearchResultItem__TotalPersonsText"
                  >
                    {searchedTravellersCount}
                  </ElementsSDK.Typography>
                </span>
                {isHolidayExtras && isAvailable && (
                  <ElementsSDK.Typography
                    className="yilu-Lounge__SearchResultItem__Entry"
                    size="extraSmall"
                  >
                    {t('lounges.card.entry-3hours')}
                  </ElementsSDK.Typography>
                )}
              </div>
            </div>
            <div className="yilu-Lounge__SearchResultItem__Price">
              {isAvailable ? (
                <span className="yilu-Lounge__SearchResultItem__NewPrice" aria-label={t('Price')}>
                  <ElementsSDK.Typography
                    size="extraSmall"
                    className="yilu-Lounge__SearchResultItem__NewPriceText"
                  >
                    {isHolidayExtras ? '' : t('From')}{' '}
                  </ElementsSDK.Typography>
                  <ElementsSDK.Typography variant="h3" inline translate="no">
                    {UiUtilities.formatCurrency(price, currency)}
                  </ElementsSDK.Typography>
                </span>
              ) : (
                <ElementsSDK.Typography
                  variant="h3"
                  className="yilu-Lounge__SearchResultItem__Price-unavailable"
                >
                  {t('Lounges.unavailable.date')}
                </ElementsSDK.Typography>
              )}
            </div>
            {isAvailable && (
              <ElementsSDK.Icon.ArrowRightChevron className="yilu-Lounge__SearchResultItem__RightArrow" />
            )}
          </section>
        </div>
      </div>
    </SearchResultCard>
  );
};
