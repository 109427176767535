import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { MonetaryAmount } from '@yiluhub/yilu-amp-types';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export enum TaxType {
  IPT = 'IPT',
}

export type PriceBreakdownProps = {
  subTotal: MonetaryAmount;
  vatAmount: MonetaryAmount;
  totalPrice: MonetaryAmount;
  vatPercentage: number;
  taxType?: TaxType;
};

const PriceBreakdown: React.FC<PriceBreakdownProps> = ({
  totalPrice,
  subTotal,
  vatAmount,
  vatPercentage,
  taxType,
}) => {
  const { t } = useTranslation();

  return (
    <ElementsSDK.Accordion
      expandUp={true}
      label={{ open: t('Hide price breakdown'), closed: t('Show price breakdown') }}
    >
      <div className={clsx('yilu-PriceBreakdown__Container')}>
        {subTotal && (
          <div className="yilu-PriceBreakdown__container">
            <ElementsSDK.Typography className="yilu-PriceBreakdown__label">
              {t('Subtotal')}
            </ElementsSDK.Typography>

            <ElementsSDK.Typography className="yilu-PriceBreakdown__value" translate="no">
              {UiUtilities.formatCurrency(subTotal.amount / 100, subTotal.currency)}
            </ElementsSDK.Typography>
          </div>
        )}
        {vatAmount && (
          <div className="yilu-PriceBreakdown__container">
            <ElementsSDK.Typography className="yilu-PriceBreakdown__label">
              {taxType === TaxType.IPT ? t('IPT') : t('VAT')}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography className="yilu-PriceBreakdown__value">
              {vatPercentage}% (
              {UiUtilities.formatCurrency(vatAmount.amount / 100, vatAmount.currency)})
            </ElementsSDK.Typography>
          </div>
        )}
        <div
          className={clsx('yilu-PriceBreakdown__container', 'yilu-PriceBreakdown__container-bold')}
        >
          <ElementsSDK.Typography className="yilu-PriceBreakdown__label">
            {taxType === TaxType.IPT ? t('Total (incl.IPT)') : t('Total (incl.VAT)')}
          </ElementsSDK.Typography>
          <span className="yilu-PriceBreakdown__value">
            {UiUtilities.formatCurrency(totalPrice.amount / 100, totalPrice.currency)}
          </span>
        </div>
      </div>
    </ElementsSDK.Accordion>
  );
};

export default PriceBreakdown;
