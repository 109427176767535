import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { Airport } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluEnv, yiluTracking } from 'utils';
import { getParamsToBeRemoved, setParamsToBeRemoved } from 'utils/session';

import routes from 'router/routes';

import { AirportTransferSearchFormProps } from '../../../components';
import { AirportTransferSearchResultsPageQueryParams } from '../../../utils/query-params';

const envVariables = yiluEnv.getVariables();

const useAirportTransferSearchFormProps = (
  airportTransferSearchResultsPageQueryParams: AirportTransferSearchResultsPageQueryParams,
  stationAirports: Airport[],
) => {
  const navigate = useNavigate();
  const airportTransferSearchFormProps = useMemo(() => {
    const {
      location,
      direction,
      transferType,
      transferDate,
      transferTime,
      airportDetails,
      travellersCount,
    } = airportTransferSearchResultsPageQueryParams;
    const _props: AirportTransferSearchFormProps = {
      searchInput: {
        location: location,
        direction: direction,
        transferType: transferType,
        transferDate: transferDate,
        transferTime: transferTime,
        airportDetails,
        travellersCount,
      },
      googleMapsAPIKey: envVariables.GOOGLE_MAPS_KEY!,
      stationAirports,
      isSRP: true,
      onSubmit(data) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const locationCoordinates = (data.locationDetails.coordinates ||
          data.locationDetails.location) as ElementsSDK.Coordinates;

        const nextSearchInput = {
          location: `${locationCoordinates.latitude},${locationCoordinates.longitude}`,
          airportDetails: data.airportDetails,
          direction: data.direction,
          transferType: data.transferType,
          transferDate: data.transferDate,
          transferTime: data.transferTime,
          travellersCount: data.travellersCount,
          stationId: data.stationId || airportTransferSearchResultsPageQueryParams.stationId,
        };

        const searchResultsPageQueryParams = {
          ...airportTransferSearchResultsPageQueryParams,
          ...nextSearchInput,
        };

        const paramsToBeRemoved = getParamsToBeRemoved();
        if (paramsToBeRemoved) {
          (
            Object.keys(searchResultsPageQueryParams) as Array<
              keyof typeof searchResultsPageQueryParams
            >
          ).forEach((param) => {
            if (JSON.parse(paramsToBeRemoved).includes(param)) {
              delete searchResultsPageQueryParams[param];
            }
          });

          setParamsToBeRemoved([]);
        }

        yiluTracking.sendGAEvent({
          event: 'srp_cta_clicked',
          category: 'airport-transfer-srp',
          label: 'airport_transfer_srp_cta_clicked',
        });

        navigate({
          pathname: routes.AIRPORT_TRANSFER_SEARCH_RESULTS,
          search: paramConverters.getURLSearchQuery(searchResultsPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [airportTransferSearchResultsPageQueryParams, navigate, stationAirports]);

  return airportTransferSearchFormProps;
};

export default useAirportTransferSearchFormProps;
