import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React, { MouseEvent, MouseEventHandler, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ParkingProviderId } from 'modules/parking/utils/getParkingDetails';

import './styles.scss';

type TermsLinkProps = {
  onProviderTermsClick: (event: MouseEvent) => unknown;
  onYiluTermsClick: (event: MouseEvent) => unknown;
  providerName: string;
  providerLocation?: string;
  showTermsOnModal: boolean;
  termsUrl: string;
};

const TermsLink: React.FC<TermsLinkProps> = ({
  onProviderTermsClick,
  onYiluTermsClick,
  providerName,
  showTermsOnModal,
  providerLocation,
  termsUrl,
}) => {
  const { t } = useTranslation();

  const acceptanceLabel = (
    labelContent: ReactNode,
    onButtonClick: MouseEventHandler<HTMLButtonElement> | undefined,
    showModal?: boolean,
    fullStop?: boolean,
  ) => (
    <>
      {showTermsOnModal || showModal ? (
        <button
          type="button"
          className="yilu-terms-link__terms-button"
          onClick={onButtonClick}
          tabIndex={0}
        >
          {labelContent}
          {fullStop ? '.' : ' '}
        </button>
      ) : (
        <span>
          {labelContent}
          {fullStop ? '.' : ' '}
        </span>
      )}
    </>
  );

  const yiluAcceptanceLabelContent = (
    <>
      <span>{t('I accept the')}</span>
      <ElementsSDK.Link inline tabIndex={0}>
        {t('yilu.acceptance.label.content')}
      </ElementsSDK.Link>
    </>
  );

  // As of now BE is not sending providerName field, but providerId instead
  const resolveProviderName = (providerName: string) => {
    switch (providerName) {
      case ParkingProviderId.PARK_AERO:
        return 'Park.Aero';
      case ParkingProviderId.PARK_VIA:
        return 'ParkVia';
      case ParkingProviderId.HOLIDAY_EXTRAS:
        return 'Holiday Extras';
      default:
        return providerName;
    }
  };

  const resolveLocationOrProvider = (providerName: string) => {
    if (providerName === ParkingProviderId.PARK_AERO && providerLocation) {
      return t('T&C of provider', { providerLocation: providerLocation });
    } else {
      return t('provider terms and conditions', {
        providerName: resolveProviderName(providerName),
      });
    }
  };

  const providerAcceptanceLabelContent = (
    <span>
      {t('and')}{' '}
      <ElementsSDK.Link inline target="_blank" rel="noreferrer" href={termsUrl} tabIndex={0}>
        {resolveLocationOrProvider(providerName) + '.'}
      </ElementsSDK.Link>
    </span>
  );

  const additionalTerms =
    providerName === ParkingProviderId.PARK_AERO
      ? providerAcceptanceLabelContent
      : acceptanceLabel(providerAcceptanceLabelContent, onProviderTermsClick, false);

  return (
    <ElementsSDK.Typography>
      <span className="yilu-terms-link">
        {acceptanceLabel(yiluAcceptanceLabelContent, onYiluTermsClick, true, !additionalTerms)}
        {additionalTerms}
      </span>
    </ElementsSDK.Typography>
  );
};

export default TermsLink;
